import { useFormContext } from "react-hook-form";
import { useState, useEffect } from "react";
//import SearchDropDown from "../components/searchDropDown";
import FIELDCONSTANTS from "../../src/common/Constants/FormFieldMappings";
import NumberFormat from 'react-number-format'

export const CustomDropDown = (props) => {
	const { register } = useFormContext();
	//console.log({ ...props.otherProps })

	return (
		<div className="ddlcontainer">
			<select
				className="ddlstyle"
				{...register(props.name, { ...props.otherProps.validation })}
				{...props.otherProps}
			>
				<option value=""> {props.t(props.otherProps.display_id)}</option>
				{props.otherProps?.child?.map((object, i) => (
					<option key={i + "drop"} value={object.name} className="ddloption" selected={object.selected === true ? true : ""}>
						{object.value}
					</option>
				))}
			</select>
		</div>
		// <SearchDropDown object={props.otherProps} />
	);
};

export const CustomRadio = (props) => {
	const { register } = useFormContext(); // retrieve all hook methods
	const [isdisabled, setIsdisabled] = useState(false)
	useEffect(() => {
		let viewas_Id = (window.location.href.indexOf('viewas_Id'));
		if (viewas_Id > -1) {
			setIsdisabled(true)
		}
	}, []);
	return (
		<div>
			<ul className="Radio-buttons-Elevator">
				{props.otherProps?.child?.map((object, i) => (
					<li key={i + 'radioli'}>
						<div className="radio-service" >
							<label className="col-12" key={i + "radio"} htmlFor={i + "radio" + object.field_id}>
								<input
									{...register(props.name, { ...props.otherProps.validation })}
									{...object}
									type="radio"
									key={i + "radio"}
									name={props.name}
									value={object.code}
									id={i + "radio" + object.field_id}
									className='radiofit'
									disabled={isdisabled}
								/>
								<label htmlFor={i + "radio" + object.field_id} className="radio-option-label">
									<div className="checker"></div>
									{props.t((object.display_id).replace(/_/g, '.'))}
								</label>
							</label>
						</div>
					</li>
				))}
			</ul>
		</div>
	);
};

export const CustomInput = (props) => {
	const { register, getFieldState, formState } = useFormContext({
		mode: "onChange",
	}); // retrieve all hook methods
	// useEffect(() => {
	//   console.log("input props --->", formState);
	//   // const nameField = props.name;
	// }, [getFieldState(props.name).isDirty]);
	const [isdisabled, setIsdisabled] = useState(false)
	useEffect(() => {
		let viewas_Id = (window.location.href.indexOf('viewas_Id'));
		if (viewas_Id > -1) {
			setIsdisabled(true)
		}

		if (["ServiceRequest.ContactInfo.SubHeader1.FirstName", "ProfileSettings.ContactInfo.Form.Label10",
			"ServiceRequest.Location.BuildingAddress.Placeholder.title2"].includes(props.otherProps.display_id)) {
			setIsdisabled(true)
		}
	}, []);

	return (

		props.otherProps.display_id == "ProfileSettings.ContactInfo.Form.Label10" ?
			<div className="contact_number">
				{props?.userDetails?.country_code_2 + " "}
				<NumberFormat value={props?.userDetails?.phone_2} displayType={'text'} format={getNumberFormat(props)} />
			</div> :
			<div>
				<input
					defaultValue={getInputDefaultValue(props)}
					{...register(props.name, { ...props.otherProps.validation })}
					{...props.otherProps}
					disabled={isdisabled}
					id="Floor-Design"
					maxLength={getInputMaxlength(props)}
					placeholder={getInputPlaceholder(props)}
					className={getCSSClass(props)}
				/>
			</div>
	);
};

export const CustomTextArea = (props) => {

	const { register, getFieldState, formState } = useFormContext(); // retrieve all hook methods

	const countryId = props?.userDetails?.countryId;

	return (
		<div className="textarea-container">
			<textarea
				className="txtarea"
				maxLength={getTextAreaMaxlength(props, countryId)}
				placeholder={props.t(props.otherProps.display_id)}
				{...register(props.name, { ...props.otherProps.validation })}
				{...props.otherProps}
			/>

			{props.otherProps.validation.field_required == true ? <sup className="description-asterisk" id="TextDescription">*</sup> : ""}
			{showCharacterCount(props, countryId)}
		</div>
	);
};

export const CustomCheckBox = (props) => {
	const { register } = useFormContext(); // retrieve all hook methods
	const [isdisabled, setIsdisabled] = useState(false)
	useEffect(() => {
		let viewas_Id = (window.location.href.indexOf('viewas_Id'));
		if (viewas_Id > -1) {
			setIsdisabled(true)
		}
	}, []);

	return (
		<div>
			{props.otherProps?.child?.map((object, i) => (
				<label key={i + "checkBox"} htmlFor={props.name}>
					<input
						name={props.name}
						type="checkbox"
						key={i + "chkCTM"}
						value={object.value}
						{...register(props.name, { ...props.otherProps.validation })}
						id={object.name}
						disabled={isdisabled}
					/>
					{object.name}
				</label>
			))}
		</div>
	);
};

export const CustomButton = (props) => {
	const { register, getFieldState, formState } = useFormContext(); // retrieve all hook methods

	return (
		<button
			className="button"
			{...register(props.name, { ...props.otherProps.validation })}
			{...props.otherProps}
		>
			{props.otherProps.display_name}
		</button>
	);
};

const getInputPlaceholder = (props) => {
	switch (props.otherProps.display_id) {
		case "ServiceRequest.Location.Floor.Placeholder.title3":
			return props.t(props.otherProps.display_id);
		case "ServiceRequest.Location.DropDown.Placeholder.title3":
			return props.t("ServiceRequest.Location.Input2.Placeholder");
		default:
			return "";
	}
};

const getInputDefaultValue = (props) => {
	switch (props.otherProps.display_id) {
		case "ServiceRequest.ContactInfo.SubHeader1.FirstName":
			return (props?.userDetails?.first_name) + ' ' + (props?.userDetails?.last_name);
		default:
			return "";
	}
};

const getInputMaxlength = (props) => {
	switch (props.otherProps.display_id) {
		case "ServiceRequest.Location.Floor.Placeholder.title3":
			return 10;
		default:
			return "";
	}
};

const getTextAreaMaxlength = (props, countryId) => {
	switch (props.otherProps.display_id) {
		case "ServiceRequest.DescriptionOfIncident.Description.Placeholder":
			return ["3", "11"].includes(countryId) ? 123 : countryId == 22 ? 38 : countryId == 13 ? 94 : ["15", "17", "12", "5"].includes(countryId) ? 100 : "";
		default:
			return "";
	}
};

const getNumberFormat = (props) => {

	switch (props?.userDetails?.country_code_2) {
		case "+43":
			return "### #######";
		case "+49":
			return "############";
		case "+1":
			return "(###) ### ####";
		case "+852":
			return "#### ####";
		case "+65":
			return "#### ####";
		case "+33":
			return "# ## ## ## ##";
		case "+86":
			return "### #### ####";
		case "+82":
			return "## #### ####";
		default:
			return "##########";
	}
};

const showCharacterCount = (props, countryId) => {
	switch (props.otherProps.display_id) {
		case "ServiceRequest.DescriptionOfIncident.Description.Placeholder":
			if (["3", "22", "13", "15", "17", "12", "5", "11"].includes(countryId)) {
				return (
					<span className="character-count" id="CharacterCount">
						{props.charCount} / {["3", "11"].includes(countryId) ? 123 : countryId == 22 ? 38 : countryId == 13 ? 94 : ["15", "17", "12", "5"].includes(countryId) ? 100 : ""}
					</span>
				);
			} else return "";
		default:
			return "";
	}
};

const getCSSClass = (props) => {
	switch (props.otherProps.display_id) {
		case "ServiceRequest.Location.BuildingAddress.Placeholder.title2":
			return "building-address-disabled";
		default:
			return "";
	}
};

// const getOptionValue = (code, displayId) => {
// 	switch (displayId) {
// 		case "ServiceRequest.DescriptionOfIncident.Option1":
// 			return code == 'Y' ? false : true;
// 		default:
// 			return code;
// 	}
// };