import axios from "axios";
import LocalStorage from "../../src/helpers/LocalStorage";
import { API_BASE_URL } from "../common/Constants/api"
import URLCONSTANTS from "../common/Constants/UrlConstants"

const getUrlVars = () => {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
    });
    return vars;
}

const getConfig = () => {
    const authTokenkey = LocalStorage.getItem('access.token')
    return { headers: { Authorization: 'Bearer ' + authTokenkey, 'Content-Type': 'application/json' } }
}

const getEndPoint = (endpoint) => {

    let viewas_Idindex = (window.location.href.indexOf('viewas_Id'));

    if (viewas_Idindex > -1) {

        let refId = getUrlVars()["viewas_Id"];

        endpoint.indexOf('?') > -1 ? endpoint += '&viewas_Id=' + refId : endpoint += '?viewas_Id=' + refId
    }

    return endpoint;
}

export const getCustomFormFields = () => {

    const getCustomFormFieldsEndpoint = getEndPoint(URLCONSTANTS.GET_CUSTOM_FORM_FIELDS_ENDPOINT);
    const getServiceUserPortfolioEndpoint = getEndPoint(URLCONSTANTS.GET_SERVICE_USER_PORTFOLIO_ENDPOINT);

    const customFormFieldRequest = axios.get(API_BASE_URL + getCustomFormFieldsEndpoint, getConfig());
    const serviceUserPortfolioRequest = axios.get(API_BASE_URL + getServiceUserPortfolioEndpoint, getConfig());

    const res = axios.all([customFormFieldRequest, serviceUserPortfolioRequest]).then(axios.spread((...responses) => {

        const responseOne = responses[0]
        const responseTwo = responses[1]

        let resBuildingArray = [];
        let resUnitgArray = [];
        let resArray = {};

        if (responseTwo && responseTwo.data) {
            responseTwo.data.building.map((item, key) => {
                resBuildingArray.push({
                    "name": item.short_building_address,
                    "value": item.short_building_address.toString(),
                    "building_id": item.building_id,
                    "building_name": item.building_name,
                    "building_unique_id": item.building_unique_id,
                    "short_building_address": item.short_building_address
                })
            });

            responseTwo.data.unit.map((item, key) => {
                resUnitgArray.push({
                    "name": item.unit_name,
                    "value": item.unit_name.toString(),
                    "building_id": item.building_id,
                    "unit_id": item.unit_id,
                    "unit_name": item.unit_name,
                    "unit_unique_id": item.unit_unique_id
                })
            });

            resArray = {
                "building": resBuildingArray,
                "unit": resUnitgArray
            }

        }

        if (responseOne && responseOne.data) {
            responseOne.data.sections[0].child[0].child.push(...resArray.building);
            responseOne.data.sections[0].child[1].child.push(...resArray.unit)
        }
        return responseOne.data

    })).catch(errors => {
        // react on errors.
        console.log(errors)
        return errors.response.data
    });

    return res;

};

export const postPlaceService = (postData) => {

    const postPSREndpoint = getEndPoint(URLCONSTANTS.POST_PLACE_CALL_SERVICE);

    const res =  axios.post(API_BASE_URL + postPSREndpoint, postData, getConfig()).
        then((response) => {
            return response;
        }).catch(errors => {
            // react on errors.
            console.log(errors);
            return false;
        });
    return res;
}

export const getServicePoll = (uiniqueid,unitid) => {

    const postPSREndpoint = getEndPoint(URLCONSTANTS.POLL_GET_PSR_STATUS);

    const res =  axios.get(API_BASE_URL + postPSREndpoint+'?unique_Id='+uiniqueid+'&unit_Id='+unitid, getConfig()).
        then((response) => {
            return response;
        }).catch(errors => {
            // react on errors.
            console.log(errors);
            return false;
        });
    return res;
}