import i18n from 'i18next'
// import helpers from './helpers.js'
// import { currencyFormatter } from '../../helpers'
// import { date } from '../../helpers'
import english from '../locales/languages/en.json'

/***
 * initialize i18 on app load or else it will error out
 * since there is a lag before we init via user object */
i18n.init()

function requireJSON(locale) {
  let localeExists = true
  try {
    require(`../locales/${locale}.json`)
  } catch (e) {
    localeExists = false
  }

  return localeExists
}

//english is the default
export function initDefaultLocale(defaultLocale = 'en-us') {
  i18n.init({
    resources: {
      'en-us': {
        translations: english,
       // helpers: helpers
      }
    },
    lng: defaultLocale,
    debug: true,
    fallbackLng: defaultLocale,
    lowerCaseLng: true,
    // have a common namespace used around the full app
    ns: 'translations',
    defaultNS: 'translations',
    keySeparator: false, // we use content as keys
    // interpolation: {
    //   format: function(value, format, lng) {
    //     if (format === 'uppercase') return value.toUpperCase()
    //     if (value instanceof Date) return date.format(value, format, lng)
    //     if (format === 'currency') return currencyFormatter(value, lng)

    //     return value
    //   }
    // },
    react: {
      wait: true
    }
  })
}

export async function initI18(locale,cdnToken) {
  //locale='fr-fr'
  //cdnToken= cdnToken || 'Q6XDVhKHVkSGWR8YTWhzDC3nXeJLglA77-Ao8mUEYl9d9RLkbqjXSWv9HY8yd1tD';
  //const defaultLocale = 'en-us'
  const fallbackLng = 'en'
  //const jsonFileExists = requireJSON(locale)
  const envcdn = process.env.REACT_APP_CDN_BASE_URL
  const lang= locale.indexOf('-') > 0 ?locale.split("-")[0]+'_'+(locale.split("-")[1]).toUpperCase():locale.toUpperCase()
  
 //  TODO: make more reusable code to avoid repetition, potential use i18n XHR for imports */
 // if (jsonFileExists) {
   // await import(/* webpackMode: "eager" */ `../../locales/${locale}`)
   fetch(
    `${envcdn}/transifex/customerportal/languages/${lang}.json?${cdnToken}`,
    {cache: 'force-cache'}
   )
     .then((res) => res.json() )
      .then(loadedResources =>
        i18n.init({
          resources: {
            [locale]: {
              translations: loadedResources,
            //  helpers: helpers
            },
            en: {
              translations: english,
            //  helpers: helpers
            }
          },
          lng: locale,
         // debug: process.env === 'production' ? false : true,
          fallbackLng: fallbackLng,
          lowerCaseLng: true,
          // have a common namespace used around the full app
          ns: 'translations',
          defaultNS: 'translations',
          keySeparator: false, // we use content as keys
        //   interpolation: {
        //     format: function(value, format, lng) {
        //       if (format === 'uppercase') return value.toUpperCase()
        //       if (value instanceof Date) return date.format(value, format, lng)
        //       if (format === 'currency') return currencyFormatter(value, lng)

        //       return value
        //     }
        //   },
          react: {
            wait: true
          }
        })
      )
      .catch(err => 
        initDefaultLocale())
 
 }

export { i18n }
