const FIELDCONSTANTS = {
    "ServiceRequest.Location.DropDown.Placeholder.title1": "building_name",
    "ServiceRequest.Location.DropDown.Placeholder.title2": "unit_name",
    "ServiceRequest.Problemstatement.fieldName": "customer_perception_code",
    "ServiceRequest.ModeOfCommunication.Placeholder": "mode_of_communication",
    "ServiceRequest.DescriptionOfIncident.Description.Placeholder": "description",
    "ServiceRequest.DescriptionOfIncident.MeansOfAccess.Placeholder": "means_of_access",
    "ServiceRequest.ErrorType.Placeholder": "customer_perception_code",
    "ServiceRequest.Location.Floor.Placeholder.title3": "floor",
    "ServiceRequest.ContactInfo.SubHeader1.FirstName": "contact_name",
    "ProfileSettings.ContactInfo.Form.Label10": "telephone",
    "ServiceRequest.ResponsePreference.DropDown.Placeholder": "response_preference",
    "ServiceRequest.Location.DropDown.Placeholder.title3": "floor",
    "ServiceRequest.Location.BuildingAddress.Placeholder.title2": "building_address",
    "ServiceRequest.DescriptionOfIncident.Option1": "unit_out_of_service",
    "ServiceRequest.DoorCondition.Placeholder": "door",
    "ServiceRequest.Vandalism.Placeholder": "Vandalism_act",
    "ServiceRequest.Problemstatement.fieldName1": "customer_perception_code",
    "ServiceRequest.ResponseTime.fieldName" : "response_preference"
};

export default FIELDCONSTANTS;