import React from 'react';
import './InProccessPopup.css'
import i18next from 'i18next'


const InProccessPopup = ({  step, props }) => {


  return (
    <div className="service-request-modal-Inprocess--alert">
     
        <div className='PlaceCallSuccessPopUp'>
          <div className='PlaceCallSuccessPopUpLogo'>
            {/* <SVG src={SuccessTick} /> */}
            <div className="spinnerdiv">
            <div className="bt-spinner"></div>
            </div>
          </div>
          <p className='PlaceCallSuccessPopUpMsg'>
          {step==1 ? i18next.t('ServiceRequest.InprocessRequest.message1')
        :  i18next.t('ServiceRequest.InprocessRequest.message2')
        }
          </p>
          
         
        </div>
    </div>
  )
}


export default InProccessPopup