import React, { Component } from 'react'
import ReactDOM from 'react-dom'

export default class Modal extends React.Component{
    constructor(props) {
        super(props)
        this.el = document.createElement('div')
        //Modal container already in DOM
        this.modalRoot = document.getElementById('modal-root')
    }
    componentDidMount() {
      // Append the element into the DOM on mount.
      this.modalRoot.appendChild(this.el)
    }
  
    componentWillUnmount() {
      // Remove the element from the DOM when we unmount
      this.modalRoot.removeChild(this.el)
    }
    render() {
       const { className, children, isVisible, closePopup, isCloseIconVisible } = this.props
       return ReactDOM.createPortal(
        <div>
        <div className='modal-overlay'/>
          <div className='psrmodal'>
        
         {isCloseIconVisible == false ?'': <a className="close"  onClick={closePopup}>×</a>}
        
          {/* <button onClick={this.props.closePopup}>close me</button> */}
          <div className='modal-content'>
          {children}
          </div>
          </div>
         
         
        </div>
      ,
      this.el
    )
    }
  }
