import React from 'react';

const Label = (props) => {

    const {label} = props;

    return (
        <label className='Edit-label'>{label}</label>
    );
};

export default Label;
