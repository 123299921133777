// for IE versions below 11
const LocalStorage =
  typeof localStorage !== 'undefined' && localStorage
    ? localStorage
    : {
        _data: {},
        setItem: (id, val) => (LocalStorage._data[id] = String(val)),
        getItem: id =>
          LocalStorage._data.hasOwnProperty(id)
            ? LocalStorage._data[id]
            : undefined,
        removeItem: id => delete LocalStorage._data[id],
        clear: () => (LocalStorage._data = {})
      }

export default LocalStorage
