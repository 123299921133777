import PayLoadData from "../assets/PayloadData.json";

export const getPayloadData = (postData, isBuildingSelecetd, formFields, user) => {
    if (postData.building_name !== "" && isBuildingSelecetd == true) {
        let buildings = formFields.sections[0].child[0].child.find(item => item.short_building_address == postData.building_name);
        let newUnitOptions = formFields.sections[0].child[1].child.filter((item) => item.building_id == buildings.building_id);

        if (postData.unit_name == "" || (postData.unit_name != "" && !newUnitOptions.includes(postData.unit_name))) {
            postData = {
                ...postData,
                // Bug- User Story 817497:Akash
                // purpose- if in Building address having # symbol user will not able to place the service request . so we have removed from the payload. 
                building_name: postData.building_name.replace(/#/g, ''),
                unit_name: newUnitOptions[0].name,
                unit_id: newUnitOptions[0].unit_id,
                building_id: buildings.building_id,
                building_address: buildings.short_building_address.replace(/#/g, '') // removing the # symbol from Building name and Building address for SGP
            }
        }
    }

    if (postData.unit_name !== "" && isBuildingSelecetd == false) {
        let units = formFields.sections[0].child[1].child.find(item => item.unit_name == postData.unit_name);
        let newBuildingOptions = formFields.sections[0].child[0].child.filter((item) => item.building_id == units.building_id);

        if (postData.building_name == "" || (postData.building_name != "" && !newBuildingOptions.includes(postData.building_name))) {
            postData = {
                ...postData,
                building_name: newBuildingOptions[0].name,
                unit_id: units.unit_id,
                building_id: newBuildingOptions[0].building_id,
                building_address: newBuildingOptions[0].short_building_address
            }
        }
    }


    postData = {
        ...postData,
        user_id: user.user_id,
        contact_name: `${user.first_name} ${user.last_name}`,
        position: user.jobTitle ? user.jobTitle : "",
        telephone: getFormattedPhoneNumber(user.phone_2),
        email: user.email,
        localUIN: user.user_local_id === null ? "0" : user.user_local_id.toString(),
        isd_code: user.country_code_2 ? user.country_code_2 : ""
    }

    PayLoadData.data = { ...PayLoadData.data, ...postData };

    return PayLoadData;
}

const getFormattedPhoneNumber = (phone_2) => {
    let phone2_formatted = phone_2
    if (phone_2 != undefined && phone_2.length === 14 && phone_2[0] === '0') {
        phone2_formatted = phone_2
    }
    else if (phone_2 != undefined && phone_2.length === 13) {
        phone2_formatted = '0' + phone_2
    }

    return phone2_formatted;
}