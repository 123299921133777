import React, { useImperativeHandle, useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import FIELDTYPECONSTANTS from "../common/Constants/FieldTypeConstants";
import Label from "../components/Label";
import { CustomDropDown, CustomRadio, CustomTextArea, CustomInput } from "../components/CustomFormFields";
import FIELDCONSTANTS from "../common/Constants/FormFieldMappings";

export const CustomFormWrapper = React.forwardRef((props, ref) => {

	const [fullForm, setFullForm] = useState();
	const [formData, setFormData] = useState(props.formArray);
	const [characterCount, setCharacterCount] = useState(0);

	useEffect(() => {
		setFullForm(props.formArray)
	}, [props.formArray]);

	const methods = useForm({
		mode: "all",
		reValidateMode: "onChange",
	});

	const getFieldComponent = (field, index) => {

		if (!field.validation.field_enabled) {
			return <></>;
		}

		switch (field.type) {
			case FIELDTYPECONSTANTS.DROPDOWN:
				return (
					<div className="drop-down-container" key={field.display_name + "field"}>
						<label className='Edit-label'>
							{field.validation.field_required == true ? <sup>*</sup> : <></>}
							{props.t(field.display_id)}
						</label>
						<CustomDropDown
							key={field.display_name}
							name={FIELDCONSTANTS[field.display_id]}
							otherProps={field}
							t={props.t}
							userDetails={props.userDetails}
						/>
					</div>
				);
			case FIELDTYPECONSTANTS.RADIO:
				return (
					<>
						<div>
							<label className='Edit-label'>
								{field.validation.field_required == true ? <sup>*</sup> : <></>}
								{props.t(field.display_id)}
							</label>
						</div>
						<div>
							<CustomRadio
								key={field.display_name}
								name={FIELDCONSTANTS[field.display_id]}
								otherProps={field}
								t={props.t}
								userDetails={props.userDetails}
							/>
						</div>
					</>
				);

			case FIELDTYPECONSTANTS.TEXTAREA:
				return (
					<>
						<CustomTextArea
							key={field.display_name}
							name={FIELDCONSTANTS[field.display_id]}
							otherProps={field}
							t={props.t}
							charCount={characterCount}
							userDetails={props.userDetails}
						/>
					</>
				);

			case FIELDTYPECONSTANTS.TEXT:
				return (
					<>
						<div>
							<label className='Edit-label'>
								{field.validation.field_required == true ? <sup>*</sup> : <></>}
								{props.t(field.display_id)}
							</label>
						</div>
						<div>
							<CustomInput
								key={field.display_name}
								name={FIELDCONSTANTS[field.display_id]}
								otherProps={field}
								t={props.t}
								userDetails={props.userDetails}
							/>
						</div>
					</>
				);

			default:
				return <></>;
		}
	};

	const onSubmit = (data) => {
		return data;
	};

	useImperativeHandle(ref, () => ({
		setFomrNewValue(e, fullForm) {
			setFullForm({ ...e });
		},
		getFormValue() {
			return methods.getValues();
		},
		setFormValueCustom(val) {
			for (const property in val) {
				methods.setValue(property, val[property]);
			}
		},
		customReset() {
			return methods.reset();
		},
		customSetCharacterCount(count) {
			setCharacterCount(count)
		}
	}));

	const getPSRHeaderText = () => {
		switch (props?.userDetails?.countryId) {
			case "3":
				return (
					props.t('ServiceRequest.Note.LabelText')
				);
			case "11":
				return (
					props.t('ServiceRequest.Note.LabelText')
				);

			default:
				return props.t('ServiceRequest.DescriptionOfIncident.FootnoteOtisONE');
		}
	};

	const getOtisNumber = () => {
		switch (props?.userDetails?.countryId) {
			case "3":
				return "";
			case "22":
				return "";
			case "13":
				return "";
			case "15":
				return "";
			case "11":
				return "";
			case "17":
				return props.t('OTISOne.Number')
			default:
				return props.t('OTISLine.Number');
		}
	};

	const getAdditionalDetailsSubHeading = (displayId) => {
		if (displayId == "ServiceRequest.Location.DropDown.Placeholder.title6") {
			return <span style={{ "text-transform": "lowercase" }}>{props.t('ServiceRequest.Location.DropDown.Placeholder.title8')}</span>
		} else return "";
	};

	return (

		<>
			<hr className="psrhr" />
			<div className="service-request-modal--form-Note">
				<h3 id="FNote">
					{(getPSRHeaderText()) + '  ' + getOtisNumber()}
				</h3>
			</div>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)} onChange={props.handleChange(fullForm, formData)}>
					{fullForm &&
						fullForm?.sections?.map((section, i) => {
							return section.section_enabled === "true" && section.section_required === "true" ? (
								<div key={i + "section"} className="service-request-modal--form topshow">
									<label className="service-request-modal--form-title">
										{props.t(section.display_id)} {getAdditionalDetailsSubHeading(section.display_id)}
									</label>
									{section.child.length > 0 &&
										section.child.map((field, index) => {
											return getFieldComponent(field, index);
										})
									}
								</div>
							) : (<></>);
						})
					}
				</form>
			</FormProvider>
		</>

	);
});