import React from 'react';
import SVG from 'react-inlinesvg';
import SuccessTick from '../images/SuccessTick.svg';
import ErrorIcon from '../images/Error_Icon.svg';
import './PlaceCallPopUp.css';
import i18next from 'i18next';
import WarningIcon from '../images/Warning_Icon.svg'


const PlaceCallPopUp = ({ togglePopup, errorMessage, statusCode, displayMessage }) => {

  const getSuccessDetails = (statusCode, errorMessage) => {
    switch (statusCode) {
      case 0:
        return false;
      case 1:
        return true;
      case 2:
        return false;
      case 3:
        return true;
      default:
        return errorMessage;
    }
  };

  const getSVGURL = (statusCode) => {
    switch (statusCode) {
      case 0:
        return WarningIcon;
      case 1:
        return ErrorIcon;
      case 2:
        return SuccessTick;
      case 3:
        return ErrorIcon;
      default:
        return ErrorIcon;
    }
  };

  const getHeaderMessage = (statusCode) => {
    switch (statusCode) {
      case 0:
        return i18next.t('ServiceRequest.info.Header');;
      case 1:
        return i18next.t('ServiceRequest.Error.Header');
      case 2:
        return i18next.t('ServiceRequest.Success.Header');
      case 3:
        return i18next.t('ServiceRequest.Error.Header');
      default:
        return i18next.t('ServiceRequest.Error.Header');
    }
  };

  const isError = getSuccessDetails(statusCode, errorMessage);


  return (
    <div className="service-request-modal--alert">
      {!isError ? (
        <div className='PlaceCallSuccessPopUp'>
          <div className='PlaceCallSuccessPopUpLogo'>
            <SVG src={getSVGURL(statusCode)} />
          </div>
          <p className='PlaceCallSuccessPopUpMsg'>
            <h6>{getHeaderMessage(statusCode)}</h6>
          </p>
          <p>{displayMessage}</p>
          <button className='PlaceCallSuccessPopUpBtn' onClick={() => { window.location.reload(false) }}>
            {i18next.t('ServiceRequest.Success.Button')}
          </button>
        </div >
      ) : (
        <div className='PlaceCallSuccessPopUp'>
          <div className='PlaceCallSuccessPopUpLogo'>
            <SVG src={getSVGURL(statusCode)} />
          </div>
          <p className='PlaceCallSuccessPopUpMsg'>
            <h6>{getHeaderMessage(statusCode)}</h6>
          </p>
          {/* <header className="error-header">
            <h3>{i18next.t('ServiceRequest.Error.Header')}</h3>
          </header> */}
          <p>{displayMessage}</p>
          <button className="service-request-modal--alert-button" onClick={togglePopup}>
            {i18next.t('ServiceRequest.Success.Button')}
          </button>
        </div>
      )}
    </div>
  )
}


export default PlaceCallPopUp